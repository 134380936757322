<template>
  <div class="userinfo-container">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span style="vertical-align: middle">{{ orderInfo.name }}</span>
    </div>
    <div class="userinfo-box">
      <Loading :show="loading">
        <div data-spm="userinfo">
          <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
            <div class="next-loading-wrap">
              <div class="sc-iBaQBe cGFAXW">
                <div class="sc-eggMyH fLwpMi">
                  <h3>用户基本信息</h3>
                  <div style="margin-left: 16px">
                    <button
                      type="button"
                      :disabled="orderInfo.is_copy"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="edit"
                    >
                      <span class="next-btn-helper">编辑基本信息</span>
                    </button>
                  </div>
                </div>
                <div class="next-row next-row-wrap wind-rc-data-fields">
                  <form-label label="权限名称">
                    {{ orderInfo.name || "-" }}
                  </form-label>
                  <form-label label="PID"> {{ orderInfo.id }} </form-label>
                  <form-label label="创建时间">
                    {{ orderInfo.create_time }}
                  </form-label>
                  <form-label label="所属应用">
                    {{ orderInfo.client_name }}
                  </form-label>
                  <form-label label="备注">
                    {{ orderInfo.remark || "-" }}
                  </form-label>
                  <form-label label="域">
                    {{ orderInfo.domain||'-' }}
                  </form-label>
                  <form-label label="跨域拷贝源域名">
                    {{ orderInfo.copy_from_permission_domain||'-' }}
                  </form-label>
                </div>
                <div class="sc-eggMyH fLwpMi">
                  <h3>权限JSON</h3>
                </div>
                <div class="policycontent">
                  <textarea
                    disabled
                    style="
                      overflow: auto;
                      font-family: monaco;
                      padding: 0.8rem 1rem;
                      width: 100%;
                      min-height: 320px;
                      max-height: 540px;
                      line-height: 1.1rem;
                      color: rgb(0, 0, 0);
                      border-color: rgb(221, 221, 221);
                      background: rgb(247, 249, 250);
                      resize: none;
                      margin-top: 8px;
                    "
                    data-spm-anchor-id="a2c8b.12215514.policycontent.i0.6eef336a0m4pIu"
                    v-html="orderInfo.permission_json"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </div>
    <tabs-content ref="tabsContent" @create_init="get_info_user" />

    <Drawer
      v-model="drawer"
      title="编辑基本信息"
      :closable="false"
      draggable
      width="40"
    >
      <el-form
        ref="ruleForm"
        label-position="top"
        :model="form"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <!-- <el-form-item label="所属应用" prop="client_uuid">
          <el-select v-model="form.client_uuid" placeholder="请选择">
            <el-option
              v-for="item in client_list"
              :key="item.client_uuid"
              :label="item.client_name"
              :value="item.client_uuid"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="权限JSON" style="min-width: 80%">
          <json-editor
            ref="jsonEditor"
            v-model="resources"
            :result-info="resources"
            read-only
            @onJsonChange="onJsonChange"
          />
          <!-- <div v-else style="white-space: pre-wrap;line-height: 20px">{{resources}}</div> -->
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入内容"
          />
        </el-form-item>
      </el-form>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            @click="submit('ruleForm')"
          >确定</el-button
          >
          <!-- <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submit('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button> -->
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="close('ruleForm')"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  get_info,
  edit_permission,
  get_permission_client_info
} from '@/api/policies'
import jsonEditor from '@/components/jsoneditor'
import tabsContent from './tabs_content.vue'

export default {
  components: {
    jsonEditor,
    tabsContent
  },
  data () {
    return {
      edit_ing: false,
      resources: '',
      drawer: false,
      loading: false,
      activeName: '1',
      orderInfo: {},
      tableData: [],
      client_list: [],
      form: {},
      rules: {
        role_name: [{ required: true, message: '不能为空', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.get_info_user()
    this.get_permission_client_info_list()
  },

  methods: {
    close (formName) {
      this.drawer = false
      this.$refs[formName].resetFields()
    },

    onJsonChange (val, has) {
      this.resources = JSON.stringify(val)
    },
    async get_permission_client_info_list () {
      const { data } = await get_permission_client_info()
      console.log(data, '2222')
      this.client_list = data
    },
    get_info_user () {
      const id = this.$route.query.id
      // this.loading = true
      get_info(id).then((res) => {
        this.loading = false
        this.orderInfo = res.data
        this.resources = res.data.permission_json
      })
    },
    edit () {
      this.drawer = true
      this.form = JSON.parse(JSON.stringify(this.orderInfo))
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            permission_id: this.$route.query.id,
            permission_json: this.resources
          }
          edit_permission(params).then((res) => {
            this.drawer = false
            this.get_info_user()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/policies')
    }
  }
}
</script>
<style>
.ivu-drawer-body {
  padding: 0;
}
.ivu-drawer-body .el-form {
  padding: 16px !important;
}
</style>

<style scoped>
.userinfo-container >>> .el-tabs__item {
  font-weight: 400 !important;
}
.userinfo-box {
  padding-top: 25px;
  color: #333;
}
.next-loading {
  width: 100%;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
h3,
h4 {
  margin-bottom: 7px !important;
  font-size: 14px;
}
.fLwpMi div:first-of-type {
  display: inline-block;
  margin-left: 40px;
}
.cGFAXW {
  margin-bottom: 20px;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.eEgMuO {
  display: flex;
  margin-bottom: 8px;
  width: 50%;
  min-width: 300px;
}
.TJhJs {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.eRLVLq {
  margin-right: 16px;
  width: 164px;
}
</style>
