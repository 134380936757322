<template>
  <div>
    <Loading :show="loading">
      <el-tabs
        v-model="be_added_type"
        type="card"
        @tab-click="tabChange"
      >
        <el-tab-pane label="授予的用户组" name="4">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的组" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">授予用户组</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_groups_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的组" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_groups_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="授予的用户" name="3">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的用户" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">授予用户</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_roles_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="web_name"
                  label="用户名称/显示名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/user/handle')"
                    >
                      {{ row.web_name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column prop="address" label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的用户" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_roles_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="web_name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/user/handle')"
                    >
                      {{ row.web_name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="授予的角色" name="2">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="drawer = true"
          >
            <span class="next-btn-helper">授予角色</span>
          </button>
          <el-table
            :header-cell-style="headerCallStyle"
            :data="user_permissions_direct_list"
            border
            style="width: 100%"
            class="table-box"
          >
            <el-table-column
              prop="name"
              label="权限名称"
            >
              <template slot-scope="{row}">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="href(row, '/roles/handle')"
                >
                  {{ row.name }}
                </button>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="address" label="操作">
              <template slot-scope="{row}">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="remove_group(row)"
                >
                  <span class="next-btn-helper">移除授权</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="secret" name="5">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="drawer = true"
          >
            <span class="next-btn-helper">授予secret</span>
          </button>
          <el-table
            :header-cell-style="headerCallStyle"
            :data="secret_list"
            border
            style="width: 100%"
            class="table-box"
          >
            <el-table-column
              prop="name"
              label="权限名称"
            />
            <el-table-column prop="address" label="操作">
              <template slot-scope="{row}">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="remove_group(row)"
                >
                  <span class="next-btn-helper">移除授权</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </Loading>

    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      :size="700"
      :title="tabName[be_added_type]"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <!-- <el-alert
          :title="tabName[be_added_type]"
          type="warning"
          :closable="false"
          show-icon
        /> -->
        <transfer
          ref="transfer"
          label-type="1"
          :list-new="roles_list"
          :type="be_added_type"
          @change_transfer="change_transfer"
          @select_change="select_change"
        />

      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            :loading="loading_but"
            @click="add"
          >
            <span class="next-btn-helper">确定</span>
          </el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import request from '@/utils/request'
import transfer from '@/components/transfer'
import { add_permission, del_permission } from '@/api'
import { get_all_permissions } from '@/api/permissions'
import { get_permission_secret_list } from '@/api/policies'

export default {
  components: {
    transfer
  },
  data () {
    return {
      drawer: false,
      loading: false,
      loading_but: false,
      be_added_type: '4',
      tabs_item_children: '1',
      tabName: {
        '5': 'secret',
        '4': '添加用户组',
        '3': '添加用户',
        '2': '新增角色',
        '1': '新增权限'
      },
      roles_list: [],
      group_groups_direct_list: [],

      be_added_id: [],
      add_to_id: [],
      page: { search: '', pageNum: 1, pageSize: 10, pageMax: true },

      //   直接
      user_groups_direct_list: [],
      user_roles_direct_list: [],
      user_permissions_direct_list: [],

      // 间接
      group_groups_indirect_list: [],
      user_roles_indirect_list: [],
      user_permissions_indirect_list: [],

      secret_list: []
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  mounted () {
    const query = Object.assign({}, this.$route.query, { be_added_type: this.be_added_type })
    this.$router.push({ query })
    this.get_all_list()
    this.init()
  },
  methods: {
    href ({ id, domain_id }, url) {
      this.$router.push({
        path: url,
        query: {
          id,
          domain_id,
          be_added_type: this.$route.query.be_added_type
        }
      })
      if (this.$route.path == url) this.reload()
    },
    init () {
      if (this.be_added_type == 4) {
        // 用户组
        this.get_groups_direct_list()
        this.get_groups_indirect_list()
      } else if (this.be_added_type == 3) {
        // 角色
        this.get_user_roles_direct_list()
        this.get_user_roles_indirect_list()
      } else if (this.be_added_type == 2) {
        // 权限
        this.get_permissions_direct_list()
      } else if (this.be_added_type == 5) {
        // secret
        this.get_permission_secret_list_auth()
      }
    },

    get_permission_secret_list_auth () {
      const params = {
        permission_id: this.$route.query.id
      }
      get_permission_secret_list(params).then(res => {
        console.log(res)
        this.secret_list = res.data
      })
    },

    async get_all_list () {
      const params = {
        base_info_domain_id: Number(this.$route.query.domain_id),
        domain_id: Number(this.$route.query.domain_id)
        // is_get_all: true
      }
      const data = await get_all_permissions(params)
      this.roles_list = data.data
    },
    // 获取直接拥有权限的用户
    async get_user_roles_direct_list () {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/permission_users',
          method: 'get',
          params: {
            permission_id: this.$route.query.id
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_roles_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    // 获取间接拥有权限的用户
    async get_user_roles_indirect_list () {
      try {
        const { data, code } = await request({
          url: '/v1/permission/indirect/permission_users',
          method: 'get',
          params: {
            permission_id: this.$route.query.id
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_roles_indirect_list = data
      } catch (error) {
        this.loading = false
      }
    },

    // 获取直接拥有权限的组
    async get_groups_direct_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/permission_groups',
          method: 'get',
          params: {
            permission_id: this.$route.query.id
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_groups_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    // 获取间接拥有权限的组
    async get_groups_indirect_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/indirect/permission_groups',
          method: 'get',
          params: {
            permission_id: this.$route.query.id
          }
        })
        this.loading = false
        console.log(data, code)
        this.group_groups_indirect_list = data
      } catch (error) {
        this.loading = false
      }
    },

    // 获取直接拥有权限的角色
    async get_permissions_direct_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/permission_roles',
          method: 'get',
          params: {
            permission_id: this.$route.query.id
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_permissions_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    add () {
      let postData = {}
      this.loading_but = true

      if (this.be_added_type == 5) {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.be_added_id,
          add_to_type: 5,
          add_to_id: this.add_to_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 4) {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.be_added_id,
          add_to_type: 4,
          add_to_id: this.add_to_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 3) {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.be_added_id,
          add_to_type: 3,
          add_to_id: this.add_to_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 2) {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.be_added_id,
          add_to_type: 2,
          add_to_id: this.add_to_id,
          user_type: this.Ole
        }
      }

      add_permission(postData).then(res => {
        this.loading_but = false
        if (res.code === 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
          this.drawer = false
          this.init()
          this.$refs.transfer.reset()
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          })
        }
      }).catch(() => {
        this.loading_but = false
      })
    },
    change_transfer (list) {
      this.add_to_id = list.map(item => item.id)
      this.Ole = list.map(() => 2)
    },

    // 自组件选择组触发
    select_change (list) {
      console.log(list, 'be_added_id')
      this.be_added_id = list
    },
    add_groups () {},
    tabChange () {
      this.tabs_item_children = '1'
      const query = Object.assign({}, this.$route.query, { be_added_type: this.be_added_type })
      this.$router.push({ query })
      this.init()
    },
    // 移除
    remove_group (row) {
      const data = {
        'client_id': 1, // 应用id
        'grant_id': row.grant_id // 授权信息id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        del_permission(data).then(res => {
          if (res.code === 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.loading = false
          this.init(this.user_type)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style>

</style>
